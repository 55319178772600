import React from "react"
import Layout from "../components/layout"
import styles from "./supportLesson.module.scss"
import globalStyles from "../services/globalStyles.module.scss"
import tmpSupportImg from "../images/tmpSupportLesson.webp"
import Header from "../components/header"
import Footer from "../components/footer"
import { graphql } from "gatsby";

const SupportLessonPage = ({ data: { texts: { _ } }, pageContext }) => {
  const languageCode = pageContext && pageContext.lang ? pageContext.lang : "en";

  return (
    <Layout header={<Header num={1} translations={_} fixed languageCode={languageCode}/>} translations={_} languageCode={languageCode}>
      <div className={[globalStyles.contentContainer169, styles.container].join(' ')}>
        <h2>LESSON</h2>
        <h1>Video Analysis</h1>
        <p>
          The following videos are essential to watch when starting out with the Video Analyser.
          If you need help with converting your video files please read the <a href="#">Video Converting Guide</a>
        </p>
        <div className={styles.content}>
          <div className={styles.imgContainer}>
            <img src={tmpSupportImg}/>
          </div>
          <div className={styles.textContainer}>
            <h1>Getting Started</h1>
            <div className={[styles.bulletItem, styles.activeBulletItem].join(' ')}>
              <div className={styles.numberBullet}>
                1
              </div>
              <span className={styles.bulletText}>
                Create Teams, Players and Formulas
              </span>
            </div>
            <div className={styles.bulletItem}>
              <div className={styles.numberBullet}>
                2
              </div>
              <span className={styles.bulletText}>
                Analyze your first game
              </span>
            </div>
            <div className={styles.bulletItem}>
              <div className={styles.numberBullet}>
                3
              </div>
              <span className={styles.bulletText}>
                Analyze your material
              </span>
            </div>
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.imgContainer}>
            <img src={tmpSupportImg}/>
          </div>
          <div className={styles.textContainer}>
            <h1>Draw on your video clips and share them</h1>
            <div className={styles.bulletItem}>
              <div className={styles.numberBullet}>
                1
              </div>
              <span className={styles.bulletText}>
                Master the Video Analyser
              </span>
            </div>
            <div className={styles.bulletItem}>
              <div className={styles.numberBullet}>
                2
              </div>
              <span className={styles.bulletText}>
                Draw on video
              </span>
            </div>
            <div className={styles.bulletItem}>
              <div className={styles.numberBullet}>
                3
              </div>
              <span className={styles.bulletText}>
                Share video clips with your team
              </span>
            </div>
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.imgContainer}>
            <img src={tmpSupportImg}/>
          </div>
          <div className={styles.textContainer}>
            <h1>Live tagging video clips</h1>
            <div className={styles.bulletItem}>
              <div className={styles.numberBullet}>
                1
              </div>
              <span className={styles.bulletText}>
                Live Tagging with XPS Tags mobile app
              </span>
            </div>
            <div className={styles.bulletItem}>
              <div className={styles.numberBullet}>
                2
              </div>
              <span className={styles.bulletText}>
                Live Mode in Video Analyser
              </span>
            </div>
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.imgContainer}>
            <img src={tmpSupportImg}/>
          </div>
          <div className={styles.textContainer}>
            <h1>Create a video presentation</h1>
            <div className={styles.bulletItem}>
              <div className={styles.numberBullet}>
                1
              </div>
              <span className={styles.bulletText}>
                Introduction to the Presentation Builder
              </span>
            </div>
            <div className={styles.bulletItem}>
              <div className={styles.numberBullet}>
                2
              </div>
              <span className={styles.bulletText}>
                Master the Presentation Builder
              </span>
            </div>
          </div>
        </div>
      </div>
      <Header num={2} translations={_} className={globalStyles.hideOnMobile} languageCode={languageCode}/>
      <Footer translations={_} languageCode={languageCode}/>
    </Layout>
  )
}

export default SupportLessonPage


export const query = graphql`
query SupportLesson($langFile: String) {
  site {
    siteMetadata {
      ip_stack_key
    }
  }
  texts: file(relativePath: {eq: $langFile}) {
    _: childTextsYaml {
      ...HeaderTranslations
      ...FooterTranslations
      chat {
        full_name
        email
        message
        send_message
        success_message
        success_info
        success
        message_placeholder
      }
    }
  }
}`